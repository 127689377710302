<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="600"
      persistent
    >
      <v-card class="px-8 py-8">
        <v-row>
          <v-col class="d-flex justify-space-between">
            <span style="font-weight: bold">
              Upload Surat
            </span>
            <span
              style="cursor: pointer"
              @click="close"
            >
              X
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="pt-4 txt16-gray50"
            cols="12"
            sm="4"
          >
            Judul Surat <span class="symbol-required">*</span>
          </v-col>
          <v-col
            cols="12"
            sm="8"
          >
            <v-text-field
              v-model="judul"
              type="text"
              outlined
              autocomplete="off"
              dense
              color="#36AC87"
              placeholder="Masukkan Judul Surat"
            />
          </v-col>
        </v-row>
        <v-row class="mt-n8">
          <v-col
            class="pt-4 txt16-gray50"
            cols="12"
            sm="4"
          >
            Lampiran <span class="symbol-required">*</span>
          </v-col>
          <v-col
            class="px-0"
            cols="12"
            sm="8"
          >
            <upload-button
              :is-loading="isLoading"
              :upload-file="uploadedFile"
              @uploadButtonClick="uploadFile"
            />
            <v-file-input
              id="upload-file"
              style="display: none"
              @change="upload"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn
              color="#2E976C"
              class="mx-1 text-capitalize"
              style="font-weight: bold; color: white"
              @click="modal = true"
            >
              Simpan
            </v-btn>
            <v-btn
              color="#CCCCCC"
              class="mx-1 text-capitalize"
              style="font-weight: bold; color: white"
              @click="close"
            >
              Batal
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <modal-konfirmasi
      :show="modal"
      @submit="confirm"
    />
  </div>
</template>

<script>
  import UploadButton from '../komponen/UploadButton.vue'
  import ModalKonfirmasi from '../komponen/modal/ModalKonfirmasi.vue'
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {

    components: {
      UploadButton,
      ModalKonfirmasi,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      dataModal: {
        type: Object,
        default: null,
      },
    },

    data: () => ({
      modal: false,
      judul: '',
      isLoading: false,
      uploadedFile: null,
      fileHash: '',
    }),

    computed: {},

    watch: {},

    created () {},

    methods: {
      close () {
        this.$emit('close')
        this.judul = ''
        this.uploadedFile = null
      },

      uploadFile () {
        document.getElementById('upload-file').click()
      },

      confirm (p) {
        // console.log(p)
        if (p === 0) {
          this.modal = false
        } else {
          this.modal = false
          this.submit()
        }
      },

      upload (e) {
        this.isLoading = true
        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('file', e)
        requestBody.append('dest', 'letter')

        axios.post(baseCDNurl + '/v1/upload', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            this.isLoading = false
            this.uploadedFile = res.data.data
            this.fileHash = res.data.data.tmp_file_hash
          }
        })
          .catch((e) => {
            this.isLoading = false
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      submit () {
        // console.log(e)
        const requestBody = {
          title: this.judul,
          file: this.fileHash,
        }

        axios.post('/v1/admin/letter-template/create', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.judul = ''
            this.uploadedFile = null
            this.$emit('init')
            this.$toast.success(res.data.message)
            this.close()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0][0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt26-black {
  @extend .h-4;
}

.symbol-required {
  color: $red-1;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #2B7DEC;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  font-size: 16px;
  color: #2B7DEC;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
